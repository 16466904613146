.top{
    display: flex;
    margin-top: 100px;
}
.top1{
    display: flex;
}
.job{
     margin-left: 50px;
    display: flex;
    margin-top: 40px;
    color: rgb(202,95,59);
    height: 20px;
}

.sol1{
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 5px;
    display: flex;
    border: none;
    padding: 20px;
    border-radius: 4px;
    font-size: 20px;
    background-color: rgb(239,248,255);
}
.form{
    border-radius: 5px;
    margin-left: 30px;
    margin-top: 50px;
    width: 20%;
    height: 10%;
    box-shadow: 0 0 30px  rgb(223, 179, 76);
    border:none ;
}
.form1{
    padding: 15px;
    margin-top: 15px;
    text-align:left;
    color: rgb(202,95,59);
}
.form2{
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    resize: none;
    text-align:left;
    font-size: 25px;
    margin-top: 15px;
    height: 100px;
    width: 80%;
    border: none;
    outline: none;
}
.ann{
    margin-top: 40px;
    justify-content: right;
    display: flex;
    margin-right: 50px;

    width: 40%;
}
.annp{
    margin-top: 20px;
    margin-right: 50px;
    
    justify-content: right;
    border-radius: 5px;
    display: flex;
    width: 40%;
    padding: 15px;
    font-size: 25px;
    background-color: rgb(210,235,240);
    color: rgb(67, 114, 109);
}
.anouncement1{
    width: 50%;
}

@media screen and (max-width: 580px) {

.job {
    margin-left: 20px;
    display: flex;
    margin-bottom: 10px;
    margin-top: 17px;
    font-size: 14px;
}
.sol1 {
    margin-top: 20px;
    margin-left: 20px;
    padding: 7px;
    font-size: 14px;

}
.top {

    margin-top: 70px;
}
.form {
    width: 25%;
    border-radius: 5px; 
    margin-left: 10px;
    margin-top: 30px;
    height: 10%;
    box-shadow: 0 0 30px rgb(223 179 76);
    border: none;
}


.form1 {
    text-align: center;
    font-size: 12px;
}
.form2 {
    font-size: 15px;
    height: 100px;
    width: 95%;
    padding-right: 10px;
    padding-left: 10px;
    
}
.ann {
    margin-top: 15px;
    text-align: center;
    font-size: 20px;
    justify-content: right;
    display: flex;
    margin-right: 25px;
    width: 30%;
}
.annp{
    margin-right: 25px;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    width: 60%;
}
  } 

@media screen and (min-width: 580px) and (max-width: 880px) {
.top{
    margin-top: 80px;
}
}

@media screen and (min-width: 880px) and (max-width: 1190px) {
    .top{
        margin-top: 120px;
    }
    }