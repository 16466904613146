@import url('https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&family=Sacramento&family=Urbanist&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "poppins",sans-serif;
}
.header1{
    display: flex;
    height: 90%;
}
.name1{
    color: rgb(207,179,111);
    margin-top: 25px;
}
.name2{
    color: rgb(202,95,59);
    margin-top: 25px;
}
.header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    z-index: 10;
    background: rgb(254,250,249);
    box-shadow: 0 6px 12px 0 rgb(245,219,158);
}
.logo{
position: relative;
width: 100%;
height: 80%;
}
.navigation{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
}

.navigation>li{
  font-weight: bold;
    font-size: 25px;
    list-style: none;
    margin: 0 10px;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    color: rgb(63, 63, 63);
}
.navigation>li>a{
  color: inherit;
  text-decoration: none;
}
.navigation>li:hover {
    color:rgb(202,95,59);
    border-bottom:5px solid rgb(207,179,111) !important;
  }
  
  .user{
    margin-top: 15px;
    width: 60px;
    height: 60px;
   
  }
  
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  @media screen and (max-width: 480px) {
    *{
      margin: 0;
    }
    .header{
      padding: 0;
    }
    .logo{
      width: 100%;
    }
      .header1{
          margin-top: 20px;
          width: 80px;
          height: 20px;
          padding: 0px;
    }
    .name1{
      margin: 0;
      font-size: 15px;
    }
    .name2{
      margin: 0;
      font-size: 15px;
    }
    .navigation{
      width: 15px;
      flex-wrap: unset;
    }
    .navigation>li{
      text-align: center;
      margin-left: 2px;
      font-weight: bold;
      font-size: 10px;
      letter-spacing: -0.5px;
    }
    .user{
      width: 30px;
      height: 30px;
      margin-top: 15px;
      margin-right: 10px;
    }
  }

 @media screen and (min-width: 580px) and (max-width: 880px) {
  *{
    margin: 0;
  }
  .header{
    padding: 0;
  }
  .logo{
    margin-top: 12px;
    width: 100%;
    height: 100%;
  }
    .header1{
        margin-top: 20px;
        width: 80px;
        height: 20px;
        padding: 0px;
  }
  .name1{
    margin: 0;
    font-size: 15px;
    margin-top: 12px;
  }
  .name2{
    margin: 0;
    margin-top: 12px;
    font-size: 15px;
  }
  .navigation{
    width: 15px;
    flex-wrap: unset;
  }
  .navigation>li{
    text-align: center;
    margin-left: 2px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.5px;
  }
  .user{
    width: 40px;
      height: 40px;
      margin-top: 16px;
      margin-right: 10px;
  }
  }
  @media screen and (min-width: 480px) and (max-width: 580px) {

    *{
      margin: 0;
    }
    .header{
      padding: 0;
    }
    .logo{
      width: 100%;
    }
      .header1{
          margin-top: 20px;
          width: 80px;
          height: 20px;
          padding: 0px;
    }
    .name1{
      margin: 0;
      font-size: 15px;
    }
    .name2{
      margin: 0;
      font-size: 15px;
    }
    .navigation{
      width: 15px;
      flex-wrap: unset;
    }
    .navigation>li{
      text-align: center;
      margin-left: 2px;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: -0.5px;
    }
    .user{
      width: 40px;
      height: 40px;
      margin-top: 16px;
      margin-right: 10px;
    }
    }
  